<template>
    <div class="centred">
        <el-card class="box-card">
            <template #header>
                <div class="card-header">
                    <span>
                        {{$t('notAuthenticated.tittle')}}
                    </span>
                </div>
            </template>
            <div class="message">
                {{$t('notAuthenticated.message')}}
            </div>

            <el-button type="primary"
                       tag="a"
                       :href="loginUrl">
                {{$t('notAuthenticated.logIn')}}
            </el-button>

        </el-card>
    </div>
</template>

<script lang="js">

    import { defineComponent } from 'vue';
    import { wwsUrlService } from '@/services/wwsUrlService'

    export default defineComponent({
         computed: {
            loginUrl() {
                return wwsUrlService.loginPage()
            }
        }
    });
</script>

<style lang="scss" scoped>

    .centred {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .message {
        margin-bottom: 18px;    
    }

    .text {
        font-size: 14px;
    }

    .item {
        margin-bottom: 18px;
    }

    .box-card {
        width: 480px;
    }

</style>