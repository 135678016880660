<template>
    <div v-loading="loading">
        <StorageStatusInformation :documnetsCount="documentCount" />

        <div>
            <div v-if="!uploadDisabled" class="tittle">
                {{ tittle }}
            </div>

            <div class="file-loader">

                <label>
                    {{ $t('fileUpload.file') }}
                    <input type="file"
                           id="file-upload"
                           ref="file"
                           :accept="acceptedTypes"
                           @change="handleFileUpload()"
                           :disabled="uploadDisabled"
                           />
                </label>
            </div>

            <div class="config-container">
                <TranslationConfig v-model="configuration"
                                   :pageNamePrefix="$t('fileUpload.configurationPrefix')"
                                   :expended="true"
                                   :excelConfigEditable="true"
                                   :documentType="documentType"
                                   @configChanged="saveConfig" />
            </div>



            <FileUploadPrompt :submitDisabled="submitDisabled"
                              @submitted="submitFile"
                              />

        </div>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { useI18n } from 'vue-i18n'

    import { TranslationConfiguration } from '@/models/TranslationConfiguration'
    import { DocumentType } from '@/models/DocumentType'

    import { navigationService } from '@/services/navigationService'
    import { homePageService } from '@/pages/Home/homePageService'
    import { userSettingsService } from '@/services/userSettingsService'
    import { userService } from '@/services/userService'
    import { translationConfigurationService } from '@/services/translationConfigurationService'

    import TranslationConfig from '@/components/TranslationConfig/TranslationConfig.vue'
    import StorageStatusInformation from './StorageStatusInformation.vue'

    import FileUploadPrompt from './FileUploadPrompt.vue'

    export default defineComponent({
        components: {
            TranslationConfig,
            StorageStatusInformation,
            FileUploadPrompt,
        },
        setup() {
            const { t } = useI18n()
            return { t }
        },
        data() {
            return {

                loading: false,
                homePageService: new homePageService(),
                userSettingsService: new userSettingsService(),
                userService: new userService(),

                file: undefined as File | undefined,
                documentCount: 0,

                configuration: translationConfigurationService.GetDefaultConfiguration(),
            };
        },
        mounted() {
            this.init();
        },
        computed: {
            acceptedTypes(): Array<string> {
                let types = ['.docx'];

                let user = this.userService.getUserFromCache();
                if (user.excelTranslationAccess) {
                    types.push('.xlsx')
                }

                return types
            },
            tittle(): string {
                let types = this.acceptedTypes.join(', ');
                return this.t('fileUpload.tittle', [types]);
            },
            uploadDisabled(): boolean {
                return this.homePageService.maxFileCountreached(this.documentCount);
            },
            submitDisabled(): boolean {
                return this.file == undefined;
            },
            documentType(): DocumentType {
                return this.file != undefined && this.file.name.endsWith('.xlsx') ? DocumentType.Xlsx : DocumentType.Docx;
            },
            defaultConfig(): TranslationConfiguration {
                return translationConfigurationService.GetDefaultConfiguration()
            },
        },
        methods: {
            async init() {
                this.loading = true;
                this.documentCount = await this.homePageService.getDocumentsCount();
                await this.setUserConfig();

                this.loading = false;
            },
            handleFileUpload() {
                this.file = (this.$refs.file as any).files[0];
            },
            async submitFile() {
                try {
                    this.loading = true;
                    var uploadedDocumentId = await this.homePageService.uploadDocument(this.file, this.configuration);
                    this.loading = false;

                    if (uploadedDocumentId) {

                        if (uploadedDocumentId == -1) {
                            this.$emit('upload-error');
                        } else {
                            navigationService.goDocumentPage(uploadedDocumentId);
                        }

                    } else {
                        //todo show message smth went wrong with upload
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            async setUserConfig() {
                let userConfiguration = await this.userSettingsService.getUserTranslationConfig();

                if (userConfiguration != null) {
                    this.configuration = translationConfigurationService.MapConfigurations(this.configuration, userConfiguration)
                }
            },

            async saveConfig() {
                await this.userSettingsService.saveTranslationConfig(this.configuration);
            },

        }
    })
</script>

<style lang="scss" scoped>
    .tittle {
        display: flex;
        justify-content: space-around;
        margin: 30px;
        font-size: 26px;
    }

    .file-loader {
        margin: 60px;
    }

    .config-container{
        margin-top: 20px;
    }

</style>