import { apiClient } from '@/utils/apiClient'
import { pagingStore } from '@/stores/pagingStore';

import { LoadSegmentsRequest } from '@/models/LoadSegmentsRequest';

export class documentService {
    requestClient: any
    pagingStore: any

    constructor() {
        this.requestClient = new apiClient();
        this.pagingStore = pagingStore();
    }

    async translateThisPage(documentId: number, useExternalTranslations: boolean) {
        const pageNumber = this.pagingStore.currentPageNumber;
        const pagesize = this.pagingStore.pageSize;

        return await this.requestClient.translateDocument(documentId, pageNumber, pagesize, useExternalTranslations);
    }

    async translateDocument(documentId: number, useExternalTranslations: boolean) {
        const pagesize = this.pagingStore.pageSize;

        return await this.requestClient.translateDocument(documentId, -1, pagesize, useExternalTranslations);
    }

    async saveTranslationDrafts(documentId: number) {
        return await this.requestClient.saveTranslationDrafts(documentId);
    }

    async clearFileTranslations(documentId: number) {
        const pagesize = this.pagingStore.pageSize;

        return await this.requestClient.clearFileTranslations(documentId, pagesize);
    }

    async loadTranslationSegments(request: LoadSegmentsRequest ) {
        return await this.requestClient.getDocumentSegments(request)
    }

    async loadConfigurations(documentId: Number) {
        return await this.requestClient.getTranslationConfig(documentId)
    }

    async downloadDocument(documentId: Number) {
        return await this.requestClient.downloadDocument(documentId)
    }

    async saveConfig(documentId: Number, docConfiguration: any) {
        return await this.requestClient.updateConfiguration(documentId, docConfiguration);
    }

    async findNextWorkSegment(documentId: Number, segmentId: Number) {
        const pageSize = this.pagingStore.pageSize;

        return await this.requestClient.findNextWorkSegment(documentId, pageSize, segmentId);
    }
}


