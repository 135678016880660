import { ReadingDirection } from '@/models/ReadingDirection';
import { IdNamePair } from '@/models/ConfigurationOptions';

export class excelConfigurationService {

    public static getDirectionOptions(): Array<IdNamePair> {
        return [
            { id: ReadingDirection.Horizontal, name: 'Horizontal' },
            { id: ReadingDirection.Vertical, name: 'Vertical' },
        ]
    }

    public static getDefaultDirectionOption(): ReadingDirection {
        return ReadingDirection.Horizontal
    }
}
