<template>
    <div class="translations">

        <div class="wrapper">
            <GoBackButton />
        </div>

        <el-tabs type="border-card">
            <el-tab-pane label="External translations usage">

                <div class="statistic-container">
                    <el-row :gutter="16">
                        <el-col :span="8">
                            <div class="statistic-card">
                                <el-statistic :value="userCharacters">

                                    <template #title>
                                        <div style="display: inline-flex; align-items: center">
                                            Characters used today
                                        </div>
                                    </template>

                                </el-statistic>
                            </div>
                        </el-col>

                        <el-col :span="8">
                            <div class="statistic-card">
                                <el-statistic :value="groupCharacters">

                                    <template #title>
                                        <div style="display: inline-flex; align-items: center">
                                            Characters used by group today
                                        </div>
                                    </template>

                                </el-statistic>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                <p>Last logs entries</p>
                <div v-loading="loading">

                    <el-table :data="lastLogItems">

                        <el-table-column label="Date">
                            <template #default="scope">
                                {{ mapDate(scope.row.date) }}
                            </template>
                        </el-table-column>

                        <el-table-column label="Translation Provider">
                            <template #default="scope">
                                {{ mapProviderName(scope.row.translationProvider) }}
                            </template>
                        </el-table-column>

                        <el-table-column prop="charactersTranslatedCount" label="Characters Used" />


                    </el-table>
                </div>

                <p>Logs by day</p>
                <div v-loading="loading">

                    <el-table :data="dailyTranslationUsages">

                        <el-table-column label="Date">
                            <template #default="scope">
                                {{ mapDate(scope.row.date) }}
                            </template>
                        </el-table-column>

                        <el-table-column prop="charactersTranslated" label="Characters Used" />


                    </el-table>
                </div>

            </el-tab-pane>
        </el-tabs>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { externalTranslationLogService } from './externalTranslationLogService';
    import { externalProvidersStore } from '@/stores/externalProvidersStore';

    import { TranslationProvider } from "@/models/TranslationProvider";

    import GoBackButton from './GoBackButton.vue'

    export default defineComponent({
        props: {
            modelValue: Object,
            disabled: {
                type: Boolean,
                defaultValue: false
            },
        },
        components: {
            GoBackButton
        },
        data() {
            return {
                externalTranslationLogService: new externalTranslationLogService(),
                externalProvidersStore: externalProvidersStore(),

                loading: false,

                userCharacters: 0,
                groupCharacters: 0,
                dailyTranslationUsages: [],
                lastLogItems: [],

            }
        },
        created() {
            this.init();
        },
        methods: {
            async init(){
                this.loading = true;

                var translationLogsData = await this.externalTranslationLogService.getTranslationLogs();
                if (translationLogsData) {

                    this.userCharacters = translationLogsData.userCharacters;
                    this.groupCharacters = translationLogsData.groupCharacters;
                    this.dailyTranslationUsages = translationLogsData.dailyTranslationUsages;
                    this.lastLogItems = translationLogsData.lastLogItems;
                }

                this.loading = false;
            },
            mapDate(dateString: any) {
                return new Date(dateString).toLocaleDateString()
            },
            mapProviderName(provider: TranslationProvider) {
                return this.externalProvidersStore.getProviderName(provider);
            }
        }
    })
</script>

<style lang="scss" scoped>
    .statistic-container {
        margin-bottom: 20px;
        background-color: var(--el-fill-color);
        padding: 20px
    }

    .statistic-card {
        padding: 20px;
        border-radius: 4px;
        background-color: var(--el-bg-color-overlay);
    }

    .wrapper {
        position: relative;
    }
</style>