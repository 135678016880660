import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-684e7764"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "status-container" }
const _hoisted_2 = { class: "message" }
const _hoisted_3 = { class: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_el_alert = _resolveComponent("el-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_progress, {
      "text-inside": true,
      "stroke-width": 40,
      percentage: _ctx.progress,
      status: _ctx.progressBarStatus
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.filesUploadedMessage), 1)
      ]),
      _: 1
    }, 8, ["percentage", "status"]),
    _createVNode(_component_el_alert, {
      type: _ctx.getPopupType,
      closable: false,
      center: "",
      "show-icon": ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.popupMessage), 1)
      ]),
      _: 1
    }, 8, ["type"])
  ]))
}