import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createBlock(_component_el_button, {
    link: "",
    type: "primary",
    size: "small",
    disabled: _ctx.documentNotActive,
    onClick: _withModifiers(_ctx.openDoc, ["prevent"]),
    onMouseup: _withModifiers(_ctx.openDocInNewTab, ["middle"])
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('fileActions.edit')), 1)
    ]),
    _: 1
  }, 8, ["disabled", "onClick", "onMouseup"]))
}