<template>
    <Transition name="squeeze" @enter="addGlow">
        <div v-if="show">
            <el-divider />

            <el-row class="centred">
                <el-col :span="3">
                    <div class="labe">
                        <p>Reading direction</p>
                    </div>
                </el-col>

                <el-col :span="3">
                    <el-select v-model="readingDirestion"
                               @change="configChanged"
                               :disabled="disabled"
                               default-first-option>

                        <el-option v-for="(item) in directions"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id" />
                    </el-select>
                </el-col>

                <el-col :span="5">
                    <el-text type="info">
                        e.g. {{sample}}
                    </el-text>
                </el-col>

            </el-row>
        </div>
    </Transition>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    import { excelConfigurationService } from './excelConfigurationService'

    import { TranslationConfiguration } from '@/models/TranslationConfiguration'
    import { DocumentType } from '@/models/DocumentType'
    import { ReadingDirection } from '@/models/ReadingDirection';

    export default defineComponent({
        props: {
            modelValue: Object as PropType<TranslationConfiguration>,
            documentType: {
                type: Number as PropType<DocumentType>,
                mandatory: true
            },
            disabled: {
                type: Boolean,
                defaultValue: false
            },
        },
        data() {
            return {
                projectCode: excelConfigurationService.getDefaultDirectionOption(),
                directions: excelConfigurationService.getDirectionOptions(),
            }
        },
        computed: {
            configuration: {
                get(): TranslationConfiguration { return this.modelValue as TranslationConfiguration; },
                set(value: TranslationConfiguration) { this.$emit('input', value); }
            },
            readingDirestion: {
                get(): ReadingDirection {
                    return this.configuration?.excelReadingDirection == null ?
                        ReadingDirection.Horizontal : this.configuration.excelReadingDirection
                },
                set(value: ReadingDirection) {
                    this.configuration.excelReadingDirection = value
                }
            },
            show(): boolean {
                return this.documentType == DocumentType.Xlsx
            },
            sample(): string {
                let sample = "";

                switch (this.readingDirestion) {
                    case ReadingDirection.Horizontal:
                        sample = "A1, B1, C1, D1, E1,"
                        break;
                    case ReadingDirection.Vertical:
                        sample = "A1, A2, A3, A4, A5,"
                        break;
                    default: ""
                }
                return sample;
            },
        },
        methods: {
            addGlow(el: any) {
                // Trigger glow animation
                el.classList.add('glow');
                setTimeout(() => el.classList.remove('glow'), 1000); // Remove glow after 1 second
            },
            configChanged() {
                this.$emit("configChanged");
            },
        }

    })

</script>

<style lang="scss" scoped>
    .centred {
        align-items: center;
    }

    .labe {
        font-size: 13px;
    }

    .squeeze-enter-active,
    .squeeze-leave-active {
        transition: all 0.5s ease;
        max-height: 230px;
    }

    .squeeze-enter-from,
    .squeeze-leave-to {
        opacity: 0;
        max-height: 0px;
    }

    .glow {
        animation: glow-effect 0.8s ease-out;
    }

    @keyframes glow-effect {
        0% {
            box-shadow: 0 0 10px rgba(121, 187, 255, 0.4);
        }

        50% {
            box-shadow: 0 0 15px rgba(121, 187, 255, 1);
        }

        100% {
            box-shadow: 0 0 5px rgba(121, 187, 255, 0.4);
        }
    }
</style>
