import '@/styles/colorPalette.scss';

import { createPinia } from 'pinia'
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'

import ElementPlus from 'element-plus'

import ComponentPlaceholder from './components/ComponentPlaceholder.vue';
import TextFormatEditor from './components/Quill/TextFormatEditor.vue'

import router from './router'

import './axios'
import 'element-plus/dist/index.css'

import { languageService } from '@/services/languageService'

const pinia = createPinia()
const i18n = languageService.getI18n();

createApp(App)
    .use(router)
    .use(pinia)
    .use(createPinia)
    .use(ElementPlus)
    .use(i18n)

    .component('ComponentPlaceholder', ComponentPlaceholder)
    .component('TextFormatEditor', TextFormatEditor)

    .mount('#app')
