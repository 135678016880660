<template>
    <div></div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import { navigationService } from '@/services/navigationService'
    import { userService } from '@/services/userService'
    import { languageService } from '@/services/languageService'

    export default defineComponent({
        data() {
            return {
                userService: new userService(),
            };
        },
        created() {
            this.loginUser();
        },
        methods: {
            async loginUser() {
                try {
                    const token = this.$route.query.accessToken;

                    if (typeof token === 'string') {
                        await this.userService.userLogin(token)
                        this.setUiLanguage();
                        navigationService.goHomePage()
                    }
                }
                catch (e) {
                    navigationService.goAnonymousPage()
                }
            },
            setUiLanguage() {
                var localeLanguage = languageService.getLocale();
                languageService.setLocale(this.$i18n, localeLanguage);
            }
        }
    });
</script>