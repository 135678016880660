<template>
    <div>

        <el-button id="submitt-file"
                   class="upload-button"
                   :disabled="submitDisabled"
                   @click="sublitButtonHandler"
                   type="primary">
            {{ $t('fileUploadPrompt.startTranslation') }}
        </el-button>

        <el-dialog v-model="uploadFileDialogVisible"
                   :title="$t('fileUploadPrompt.uploadFileTittle')"
                   width="30%"
                   align-center>
            <span>{{ $t('fileUploadPrompt.uploadFileText') }}</span>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="neverShowDialog"
                               type="info">
                        {{ $t('fileUploadPrompt.neverShowDialog') }}
                    </el-button>
                    <div>
                        <el-button @click="uploadFile"
                                   type="primary">
                            {{ $t('fileUploadPrompt.continue') }}
                        </el-button>
                        <el-button @click="closePopup">
                            {{ $t('fileUploadPrompt.backToConfigurations') }}
                        </el-button>
                    </div>
                </div>
            </template>
        </el-dialog>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { userSettingsService } from '@/services/userSettingsService'

    export default defineComponent({
        props: {
            submitDisabled: {
                type: Boolean,
                required: true
            }
        },
        created() {
            this.init();
        },
        data() {
            return {
                showFileUploadPrompt: true,
                uploadFileDialogVisible: false,
                userSettingsService: new userSettingsService(),

            };
        },
        methods: {
            async init() {
                var uploadNotificationConfig = await this.userSettingsService.getSkipUploadNotification();
                this.showFileUploadPrompt = uploadNotificationConfig == null || uploadNotificationConfig == false;
            },
            async sublitButtonHandler() {
                if(this.showFileUploadPrompt){
                    this.uploadFileDialogVisible = true;
                    return;
                }

                this.$emit('submitted');
            },
            async neverShowDialog(){
                await this.userSettingsService.hideUploadFileMessage();
                this.uploadFile();
            },
            uploadFile(){
                this.uploadFileDialogVisible = false;
                this.$emit('submitted');
            },
            closePopup(){
                this.uploadFileDialogVisible = false;
                this.showFileUploadPrompt = false;
            },
        }
    })
</script>

<style scoped>
    .upload-button {
        margin-top: 20px;
    }

    .dialog-footer {
        display: flex;
        justify-content: space-between;
    }

</style>