import { apiClient } from "@/utils/apiClient";
import { clientCache } from "@/utils/clientCache";

const oneDayInSeconds = 24 * 60 * 60;

export class userService {
    requestClient: any;

    constructor() {
        this.requestClient = new apiClient();
    }

    async userLogin(acceessTocken: string) {
        await this.requestClient.userLogin(acceessTocken);
        await this.loadUser();
    }

    async userLogout() {
        await this.requestClient.userLogout();
        this.clearUser();
    }

    async getUserName() {
        const user = await this.getUser();
        return user.userName;
    }

    async getExternlaTranslationLimit(): Promise<number> {
        const user = await this.getUser();
        return user.externalTranslationLimit;
    }

    async getUser() {
        const user = this.getUserFromCache();
        if (user !== null) {
            return user;
        }

        const loaddedUser = await this.loadUser();
        return loaddedUser;
    }

    private async loadUser() {
        const user = await this.requestClient.getUserInfo();

        if (user != null) {
            this.setUserToCache(user);
        }

        return user;
    }

    public async setUserLanguage(language: string) {
        const newUser = await this.requestClient.setInterfaceLanguage(language);

        if (newUser != null) {
            this.setUserToCache(newUser);
        }
    }

    private clearUser() {
        this.removeUserFromCache();
    }

    public getUserFromCache() {
        return clientCache.get('user');
    }

    private setUserToCache(value: string) {
        clientCache.set('user', value, oneDayInSeconds);
    }

    private removeUserFromCache() {
        clientCache.remove('user');
    }
}