import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9807cf04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message" }
const _hoisted_2 = { class: "dialog-footer" }
const _hoisted_3 = { class: "message" }
const _hoisted_4 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_dropdown, {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.pageTranslationDialogVisible = true)),
      size: "default",
      "split-button": "",
      type: "primary"
    }, {
      dropdown: _withCtx(() => [
        _createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_dropdown_item, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.documentTranslationDialogVisible = true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Translate all document ")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createTextVNode(" Translate this page ")
      ]),
      _: 1
    }),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.pageTranslationDialogVisible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pageTranslationDialogVisible) = $event)),
      title: "Translate this page",
      width: "40%",
      "show-close": false,
      "align-center": ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_el_button, { onClick: _ctx.closePageTranslateDialog }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.translatePage
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Confirm ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.pageTranslationMsg), 1),
        _createVNode(_component_el_collapse, { accordion: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_collapse_item, {
              title: "External translations",
              name: "1"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_el_checkbox, {
                    modelValue: _ctx.useExternalTranslation,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.useExternalTranslation) = $event)),
                    disabled: !_ctx.externalTranslationProviderAvailable,
                    label: "Use external translations",
                    size: "large"
                  }, null, 8, ["modelValue", "disabled"]),
                  _createVNode(_component_el_alert, {
                    type: "warning",
                    class: _normalizeClass({ hidden: _ctx.externalTranslationProviderAvailable }),
                    title: _ctx.translationProviderNotConfigured,
                    closable: false
                  }, null, 8, ["class", "title"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.documentTranslationDialogVisible,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.documentTranslationDialogVisible) = $event)),
      title: "Translate all document",
      width: "40%",
      "show-close": false,
      "align-center": ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_el_button, { onClick: _ctx.closeDocumentTranslateDialog }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.translateAll
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Confirm ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.documentTranslationMsg), 1),
        _createVNode(_component_el_collapse, { accordion: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_collapse_item, {
              title: "External translations",
              name: "1"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_el_checkbox, {
                    modelValue: _ctx.useExternalTranslation,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.useExternalTranslation) = $event)),
                    disabled: !_ctx.externalTranslationProviderAvailable,
                    label: "Use external translations",
                    size: "large"
                  }, null, 8, ["modelValue", "disabled"]),
                  _createVNode(_component_el_alert, {
                    type: "warning",
                    class: _normalizeClass({ hidden: _ctx.externalTranslationProviderAvailable }),
                    title: _ctx.translationProviderNotConfigured,
                    closable: false
                  }, null, 8, ["class", "title"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}