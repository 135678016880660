<template>
    <div>
        <el-row :gutter="24">
            <el-col :span="6" id="translate-all">

                <TranslatePageOrDocumentButton :configuration="translationConfig"
                                               @translatePageConfirmed="translatePage"
                                               @translateAllConfirmed="translateAll" />

            </el-col>

            <el-col :span="6">

                <SaveAllDraftsButton @saveConfirmed="saveAllDrafts" />

            </el-col>

            <el-col :span="6">

                <ClearDocumentButton @clearConfirmed="clearAllSegments" />

            </el-col>

            <el-col :span="6">

                <el-button id="download-document"
                           @click="downloadDoc">
                    Download document
                </el-button>

            </el-col>
        </el-row>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    import { documentService } from '../documentService'
    import { documentStore } from '@/stores/documentStore'

    import { TranslationConfiguration } from '@/models/TranslationConfiguration';

    import SaveAllDraftsButton from './SaveAllDraftsButton.vue'
    import ClearDocumentButton from './ClearDocumentButton.vue'
    import TranslatePageOrDocumentButton from './TranslatePageOrDocumentButton.vue'


    export default defineComponent({
        props: {
            modelValue: Object as PropType<TranslationConfiguration>,
        },
        components: {
            SaveAllDraftsButton,
            ClearDocumentButton,
            TranslatePageOrDocumentButton
        },
        data() {
            return {
                documentService: new documentService(),
                documentStore: documentStore(),
            };
        },
        computed: {
            translationConfig(): TranslationConfiguration {
                return this.modelValue as TranslationConfiguration;
            },
            documentId(): number {
                return Number(this.$route.params.id);
            },
        },

        methods: {
            async translatePage(userExternalTranslations: boolean) {
                this.documentStore.startLoading();
                await this.documentService.translateThisPage(this.documentId, userExternalTranslations);
                this.documentStore.stopLoading();
                this.documentStore.reloadDocument();
            },
            async translateAll(userExternalTranslations: boolean) {
                this.documentStore.startLoading();
                await this.documentService.translateDocument(this.documentId, userExternalTranslations);
                this.documentStore.stopLoading();
                this.documentStore.reloadDocument();
            },
            async saveAllDrafts() {
                this.documentStore.startLoading();
                await this.documentService.saveTranslationDrafts(this.documentId);
                this.documentStore.stopLoading();
                this.documentStore.reloadDocument();
            },
            async clearAllSegments() {
                this.documentStore.startLoading();
                await this.documentService.clearFileTranslations(this.documentId);
                this.documentStore.stopLoading();
                this.documentStore.reloadDocument();
            },
            async downloadDoc() {
                this.documentStore.startLoading();
                await this.documentService.downloadDocument(this.documentId)
                this.documentStore.stopLoading();
            },
        }
    });
</script>
