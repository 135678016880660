import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
import { TranslationConfiguration } from '@/models/TranslationConfiguration';
import { ExternalTranslationResponse } from '@/models/ExternalTranslationResponse';

import { apiClient } from '@/utils/apiClient'
import { notificationService } from '@/services/notificationService'

export class segmentApiService {
    private static instance: segmentApiService;
    private requestClient: apiClient
    private notificationService: notificationService;

    private constructor() {
        this.requestClient = new apiClient();
        this.notificationService = new notificationService();
    }

    public static getInstance(): segmentApiService {
        if (!segmentApiService.instance) {
            segmentApiService.instance = new segmentApiService();
        }
        return segmentApiService.instance;
    }

    async saveSegmentChnages(translationSegment: TranslationSegment) {
        await this.requestClient.updateTranslationState(translationSegment);
    }

    async saveSegmentState(translationSegment: TranslationSegment) {
        await this.requestClient.updateTranslationState(translationSegment);
    }

    async translateSegment(translationSegment: TranslationSegment, configuration: TranslationConfiguration) {
        const request = {
            SegmentId: translationSegment.translationSegmentId,
            Source: translationSegment.sourceData.text,
            Config: configuration
        }

        const responce = await this.requestClient.getTranslation(request);
        return responce as TranslationSegment;
    }

    async reloadSegmentTranslations(translationSegment: TranslationSegment, configuration: TranslationConfiguration) {
        const request = {
            SegmentId: translationSegment.translationSegmentId,
            Source: translationSegment.sourceData.text,
            Config: configuration
        }

        const responce = await this.requestClient.reloadSegmentTranslations(request);
        return responce as TranslationSegment;
    }

    async translateExternallySegment(translationSegment: TranslationSegment, configuration: TranslationConfiguration) {
        const request = {
            SegmentId: translationSegment.translationSegmentId,
            Source: translationSegment.sourceData.text,
            Config: configuration
        }

        try {
            const responce = await this.requestClient.getExternalTranslation(request);
            return responce as ExternalTranslationResponse;
        }
        catch (error: any) {
            this.notificationService.warning("API error", "Try again later, or contact WWS");
            return null;
        }
    }

    async addNewTranslationStyled(translationSegment: TranslationSegment, configuration: TranslationConfiguration) {

        const request = {
            SegmentId: translationSegment.translationSegmentId,
            Source: translationSegment.sourceData.text,
            Target: translationSegment.draftData,
            Config: configuration
        }

        const responce = await this.requestClient.saveTranslationStyled(request);

        return responce as TranslationSegment;
    }

    async updateTranslationStyled(translationSegment: TranslationSegment, configuration: TranslationConfiguration) {

        const request = {
            SegmentId: translationSegment.translationSegmentId,
            Source: translationSegment.sourceData.text,
            TargetOld: translationSegment.segmentTranslation?.targetSegment,
            Target: translationSegment.draftData,
            Config: configuration
        }

        const responce = await this.requestClient.updateTranslationStyled(request);
        return responce as TranslationSegment;
    }

    async updateSegmentStyles(translationSegment: TranslationSegment, configuration: TranslationConfiguration) {

        const request = {
            SegmentId: translationSegment.translationSegmentId,
            EntityId: translationSegment.segmentTranslation?.entryId,
            SegmentStyle: translationSegment.draftData.delta,
        }

        const responce = await this.requestClient.updateSegmentStyles(request);
        return responce as TranslationSegment;
    }

    async getTerminology(configuration: any, termsArray: any) {

        const data = {
            SubjectId: configuration.subjectId,
            SourceLanguageId: configuration.sourceLanguageId,
            TargetLanguageId: configuration.targetLanguageId,
            DatabaseType: configuration.databaseType,
            SearchTerms: termsArray
        };

        return await this.requestClient.getTermigology(data);

    }
}