import { TranslationConfiguration } from "@/models/TranslationConfiguration";

export class translationConfigurationService {

    public static GetDefaultConfiguration():TranslationConfiguration {

        const defatultConfig = {
            subjectId: -1,
            subSubjectId: -1,
            sourceLanguageId: -1,
            targetLanguageId: -1,
            projectCode: "0000000000",
            minMatchPercent: 50,
            databaseType: 1,
            openVariantsPopup: false,
            useExternalTranslation: false,
            externalTranslationProviders: [],
            excelReadingDirection: 0,
        } as TranslationConfiguration

        return defatultConfig
    }

    public static MapConfigurations(oldConfig: TranslationConfiguration, newConfig: TranslationConfiguration): TranslationConfiguration {

        const defaultConfig = translationConfigurationService.GetDefaultConfiguration();

        oldConfig.subjectId = newConfig.subjectId != null ? newConfig.subjectId : defaultConfig.subjectId;
        oldConfig.subSubjectId = newConfig.subSubjectId != null ? newConfig.subSubjectId : defaultConfig.subSubjectId;
        oldConfig.sourceLanguageId = newConfig.sourceLanguageId != null ? newConfig.sourceLanguageId : defaultConfig.sourceLanguageId;
        oldConfig.targetLanguageId = newConfig.targetLanguageId != null ? newConfig.targetLanguageId : defaultConfig.targetLanguageId;
        oldConfig.projectCode = newConfig.projectCode != null ? newConfig.projectCode : defaultConfig.projectCode;
        oldConfig.minMatchPercent = newConfig.minMatchPercent != null ? newConfig.minMatchPercent : defaultConfig.minMatchPercent;
        oldConfig.databaseType = newConfig.databaseType != null ? newConfig.databaseType : defaultConfig.databaseType;
        oldConfig.openVariantsPopup = newConfig.openVariantsPopup != null ? newConfig.openVariantsPopup : defaultConfig.openVariantsPopup;
        oldConfig.useExternalTranslation = newConfig.useExternalTranslation != null ? newConfig.useExternalTranslation : defaultConfig.useExternalTranslation;
        oldConfig.externalTranslationProviders = newConfig.externalTranslationProviders != null ? newConfig.externalTranslationProviders : defaultConfig.externalTranslationProviders;
        oldConfig.excelReadingDirection = newConfig.excelReadingDirection != null ? newConfig.excelReadingDirection : defaultConfig.excelReadingDirection;

        return oldConfig;
    }
}