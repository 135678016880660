import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{ show: _ctx.haveDraft }, 'hidden'])
    }, [
      _createVNode(_component_el_tooltip, {
        content: _ctx.draftSegments,
        effect: "light",
        placement: "right"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tag, { type: "warning" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.draftTag), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["content"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([{ show: _ctx.haveTranslated }, 'hidden'])
    }, [
      _createVNode(_component_el_tooltip, {
        content: _ctx.translatedSegments,
        effect: "light",
        placement: "right"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tag, { type: "success" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translatedTag), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["content"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([{ show: _ctx.havePreTranslated }, 'hidden'])
    }, [
      _createVNode(_component_el_tooltip, {
        content: _ctx.preTranslatedSegments,
        effect: "light",
        placement: "right"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tag, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.preTranslatedTag), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["content"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([{ show: _ctx.newFile }, 'hidden'])
    }, [
      _createVNode(_component_el_tooltip, {
        content: _ctx.tatalSegments,
        effect: "light",
        placement: "right"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tag, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.nweFileTag), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["content"])
    ], 2)
  ]))
}