
<template>
    <div>

        <p>
            {{ $t('userDocuments.tittle') }}
        </p>

        <div v-loading="loading" v-if="noDocuments">
            <el-empty :description="$t('userDocuments.noDocuments')" />
            <el-button type="primary"
                       @click="goToUploadFile">
                {{ $t('userDocuments.goToUpload') }}
            </el-button>
        </div>

        <div v-else class="documents">

            <el-table v-loading="loading" :data="documents">
                <el-table-column prop="id"
                                 label="Id"
                                 width="50" />

                <el-table-column width="100"
                                 prop="documentType"
                                 :label="$t('userDocuments.documentType')">
                    <template #default="scope">
                        <FileType :document="scope.row" />
                    </template>
                </el-table-column>


                <el-table-column prop="name"
                                 :label="$t('userDocuments.name')" />

                <el-table-column :label="$t('userDocuments.language')">
                    <template #default="scope">
                        {{ languages(scope.row) }}
                    </template>
                </el-table-column>

                <el-table-column :label="$t('userDocuments.createdOn')">
                    <template #default="scope">
                        {{ mapDate(scope.row.createdAt) }}
                    </template>
                </el-table-column>

                <el-table-column :label="$t('userDocuments.updatedOn')">
                    <template #default="scope">
                        {{ mapDateTime(scope.row.updatedAt) }}
                    </template>
                </el-table-column>

                <el-table-column :label="$t('userDocuments.progress')">
                    <template #default="scope">
                        <ProgressTags :rowData="scope.row" />
                    </template>
                </el-table-column>

                <el-table-column :label="$t('userDocuments.state')">
                    <template #default="scope">
                        <DocumentState :rowData="scope.row" />
                    </template>
                </el-table-column>

                <el-table-column fixed="right"
                                 :label="$t('userDocuments.operations')">
                    <template #default="scope">

                        <div class="file-actions">

                            <EditButton :rowData="scope.row" />

                            <FileActions :rowData="scope.row"
                                         @archiveAction="archiveDialog"
                                         @deleteAction="deleteDialog" />
                        </div>

                    </template>
                </el-table-column>

            </el-table>

            <el-pagination layout="prev, pager, next"
                           :total="rowCount"
                           :default-page-size="pageSize"
                           @current-change="goToPage" />

        </div>


        <el-dialog v-model="archiveDialogVisible"
                   :title="$t('userDocuments.archve')"
                   width="30%"
                   align-center>
            <span>{{ $t("userDocuments.archveMessage") }}</span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeArchiveDialog">
                        {{ $t("userDocuments.cancel") }}
                    </el-button>
                    <el-button type="primary"
                               @click="archiveConfirm">
                        {{ $t("userDocuments.confirm") }}
                    </el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog v-model="deleteDialogVisible"
                   :title="$t('userDocuments.delete')"
                   width="30%"
                   align-center>
            <span>{{ $t("userDocuments.deleteMessage") }}</span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeDeleteDialog">
                        {{ $t("userDocuments.cancel") }}
                    </el-button>
                    <el-button type="primary"
                               @click="deleteConfirm">
                        {{ $t("userDocuments.confirm") }}
                    </el-button>
                </span>
            </template>
        </el-dialog>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { ConfigurationOptions, IdNamePair } from '@/models/ConfigurationOptions'
    import { RequestOptions } from '@/models/RequestOptions'

    import { translationConfigService } from '@/components/TranslationConfig/translationConfigService'
    import { homePageService } from '@/pages/Home/homePageService'

    import ProgressTags from '@/components/Home/TranslationProgressTags.vue'
    import FileType from '@/components/Home/FileType.vue'

    import EditButton from './EditFileButton.vue'
    import FileActions from './FileActionOptions.vue'
    import DocumentState from './DocumentTranslationsState.vue'


    export default defineComponent({
        components: {
            ProgressTags,
            FileType,
            EditButton,
            FileActions,
            DocumentState,
        },
        data() {
            return {
                loading: false,
                documents: [] as any[],
                homePageService: new homePageService(),
                translationConfigService: new translationConfigService(),

                configOptions: null as ConfigurationOptions | null,

                deleteDialogVisible: false,
                archiveDialogVisible: false,

                deleteItemId: 0,
                archiveItemId: 0,

                currentPageNumber: 1,
                pageSize: 10,
                rowCount: 0,
            };
        },
        created() {
            this.init();
        },
        computed: {
            requestOptions(): RequestOptions {
                var request: RequestOptions = {
                    Paging: {
                        PageNumber: this.currentPageNumber,
                        PageSize: this.pageSize
                    },
                }
                return request;
            },
            noDocuments(): boolean {
                return this.rowCount === 0;
            }
        },
        methods: {
            async init() {
                this.loading = true;

                await this.loadDocuments();
                this.configOptions = await this.translationConfigService.getConfigOptions();

                this.loading = false;
            },
            async loadDocuments() {
                var pagedResult = await this.homePageService.loadDocuments(this.requestOptions);
                if (pagedResult != undefined && pagedResult != null) {
                    this.rowCount = pagedResult.rowCount;
                    this.documents = pagedResult.results;
                }
            },
            languages(item: any) {
                let sourceLang = this.configOptions?.languages.find((l: IdNamePair) => l.id == item.translationConfig.sourceLanguageId);
                let targeLang = this.configOptions?.languages.find((l: IdNamePair) => l.id == item.translationConfig.targetLanguageId);

                return `${sourceLang?.name} - ${targeLang?.name}`
            },

            mapDate(dateString: any) {
                return new Date(dateString).toLocaleDateString()
            },
            mapDateTime(dateString: any) {
                return new Date(dateString).toLocaleString()
            },


            archiveDialog(documentId: number) {
                this.archiveDialogVisible = true;
                this.archiveItemId = documentId;
            },

            deleteDialog(documentId: number) {
                this.deleteDialogVisible = true;
                this.deleteItemId = documentId;
            },

            async archiveConfirm() {
                this.loading = true;

                await this.homePageService.archiveDocument(this.archiveItemId);
                await this.loadDocuments();

                this.loading = false;

                this.$emit('document-achived');

                this.closeArchiveDialog();
            },

            closeArchiveDialog() {
                this.archiveDialogVisible = false;
                this.archiveItemId = 0;
            },

            async deleteConfirm() {
                this.loading = true;

                await this.homePageService.deleteDocument(this.deleteItemId);
                await this.loadDocuments();

                this.loading = false;
                this.$emit('document-deleted');

                this.closeDeleteDialog()
            },

            closeDeleteDialog() {
                this.deleteDialogVisible = false;
                this.deleteItemId = 0;
            },

            async goToPage(pageNumebr: number) {
                this.currentPageNumber = pageNumebr;
                await this.loadDocuments();
            },

            goToUploadFile() {
                this.$emit('go-file-upload');
            }

        }
    })
</script>

<style lang="scss" scoped>
    .documents {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .file-actions {
        display: flex;
        justify-content: space-around;
    }
</style>