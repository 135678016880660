<template>

    <el-button id="clear-document"
               @click="clearSegmentsDialogVisible = true"
               plain
               type="danger">
        Clear document
        <div class="bttn-img">
            <span class="material-symbols-outlined">
                ink_eraser
            </span>
        </div>
    </el-button>

    <el-dialog v-model="clearSegmentsDialogVisible"
               title="Clear document"
               width="30%"
               align-center>
        <div class="message">{{clearMsg}} </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="closeClearSegmentsDialog">Cancel</el-button>
                <el-button type="primary" @click="clearAllSegments">
                    Confirm
                </el-button>
            </span>
        </template>
    </el-dialog>

</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        emits: ['clearConfirmed'],
        data() {
            return {
                clearSegmentsDialogVisible: false,
                clearMsg: 'This action will erase all translations for this document and set all segments as not translated. Continue?',
            }
        },
        methods: {
            clearAllSegments() {
                this.$emit('clearConfirmed');
                this.closeClearSegmentsDialog();
            },
            closeClearSegmentsDialog() {
                this.clearSegmentsDialogVisible = false;
            },
        }
    })

</script>

<style lang="scss" scoped>
    .bttn-img {
        margin-left: 10px;
    }

    .message {
        font-size: 1.1em;
    }
</style>
