<template>
    <div id="segment-container" class="segment-container" ref="translationSegment">

        <div :class="[{'focused-row':focused}, {'not-loadded':!translationLoadded}, 'translation-segment']"
             v-loading="loading">

            <div id="row-number" class="row-number">
                {{segmentItem.rowNumber}}
            </div>

            <div id="source-container" class="source-container">
                <SourceSegment v-model="translationSegment.sourceData" />
            </div>

            <TranslateAndCopy v-model="segmentItem" />

            <TargetWithVariants v-model="segmentItem" />

            <TargetOptions v-model="segmentItem" />

            <div class="next-segmet-wrapper">
                <FindNextSegment :focused="focused"
                                 :translationLoadded="translationLoadded"
                                 @nextTranslateSegment="nextTranslateSegment" />
            </div>

        </div>

        <MergeButton :class="[{ show: mergePossible }, 'hidden']"                     
                     :focused="focused"
                     @merge="mergeSegments" />

        <SplitButton :class="[{ show: splitPossible }, 'hidden']"
                     :focused="focused"
                     @split="splitSegment" />

    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    import { translationSegmentService } from './translationSegmentService';

    import { SegmentEditorItem } from '@/models/SegmentEditorItem';
    import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';

    import SourceSegment from './Components/SourceSegmentView.vue'
    import TranslateAndCopy from './Components/TranslateAndCopy.vue'
    import TargetWithVariants from './Components/TargetWithVariants.vue'
    import TargetOptions from './Components/TargetOptions.vue'
    import FindNextSegment from './Components/FindNextSegment.vue'

    import MergeButton from './Components/MergeButton.vue'
    import SplitButton from './Components/SplitButton.vue'


    export default defineComponent({
        props: {
            modelValue: Object as PropType<SegmentEditorItem>,
        },
        components: {
            SourceSegment,
            TranslateAndCopy,
            TargetWithVariants,
            TargetOptions,
            FindNextSegment,

            MergeButton,
            SplitButton,
        },
        emits: [
            "input",
            "mergeSegment",
            "splitSegment",
            "nextTranslateSegment",
        ],
        data() {
            return {
                translationSegmentService: translationSegmentService.getInstance(),
            };
        },
        watch: {
            'modelValue.focused'(newVal){
                if (newVal) {
                    this.scrollToSegment();
                }
            }
        },
        computed: {
            segmentItem: {
                get(): SegmentEditorItem { return this.modelValue as SegmentEditorItem; },
                set(value: SegmentEditorItem) { this.$emit('input', value); }
            },
            translationSegment: {
                get(): TranslationSegment { return this.segmentItem.data as TranslationSegment; },
                set(value: TranslationSegment) { this.segmentItem.data = value }
            },

            focused(): boolean{
                return this.segmentItem.focused;
            },

            translationLoadded(): boolean {
                return this.translationSegment.translationVariants != null;
            },

            loading(): boolean{
                return this.segmentItem.loading;
            },
            
            copyEnabled(): boolean{
                return this.translationLoadded;
            },

            mergePossible(): boolean{
                return this.translationSegmentService.mergeIsPossible(this.segmentItem);
            },
            splitPossible(): boolean{                
                return this.translationSegmentService.splitIsPossible(this.segmentItem);
            },
        },

        methods: {
            scrollToSegment(){                
                var segmentElement = (this.$refs as any).translationSegment;
                var inViewPort = this.elementIsVisibleInViewport(segmentElement);
                var viewOptions = this.getViewOptions(inViewPort);
                (this.$refs as any).translationSegment.scrollIntoView(viewOptions);
            },
            elementIsVisibleInViewport(el: any){
              const { top, left, bottom, right } = el.getBoundingClientRect();
              const { innerHeight, innerWidth } = window;
              return top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
            },
            getViewOptions(elementInViewPort: boolean){
                // if element is not in view port, scroll to it using 'auto'
                // because 'smooth' does not work in this case

                var viewOptions = { behavior: "smooth", block: "center", inline: "center" }
                if(!elementInViewPort){
                    viewOptions.behavior = "auto";
                }

                return viewOptions;
            },
            mergeSegments(){
                this.$emit('mergeSegment', this.translationSegment.translationSegmentId);
            },
            splitSegment(){
                this.$emit('splitSegment', this.translationSegment.translationSegmentId);
            },

            nextTranslateSegment(){
                this.$emit('nextTranslateSegment', this.translationSegment.translationSegmentId);
            },
        },
    });

</script>

<style>
    .el-popover.el-popper.variants-popover {
        font-size: 19px;
    }

    .ql-container p {
        font-size: 1.4em;
    }
</style>

<style lang="scss" scoped>
    
    .segment-container {
        margin-bottom: 10px;
        position: relative;
    }
   
    .translation-segment {
        padding: 12px;
        margin-bottom: 18px;
        background-color: #ffffff;
        box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
        border-radius: 10px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;

        &:hover {
            background-color: #f5faff;
        }
    }

    .focused-row, .focused-row:hover {
        background-color: #d7ecff;
    }

    .not-loadded {
        background-color: #e9e9e9
    }

    .row-number {
        margin-top: 12px;
        width: 48px;
    }

    .source-container {
        width: 500px;
    }

    .next-segmet-wrapper {
        position: relative;
    }


</style>