<template>
    <el-card>
        <el-collapse v-model="collapseModel"
                     @change="handleChange">
            <el-collapse-item name="1">
                <template #title>
                    <div class="img-container">
                        <span class="material-symbols-outlined">
                            equalizer
                        </span>
                    </div>
                    <span class="title-value">{{tittle}}</span>

                    <div v-if="limitReached"
                         class="limit-container">

                        <el-tooltip placement="top"
                                    :content="tooltipMessage">
                            <div class="img-container">
                                <i class="limit-reached"></i>
                            </div>
                        </el-tooltip>

                    </div>
                </template>

                <el-descriptions>
                    <el-descriptions-item label="Document name:">{{documentName}}</el-descriptions-item>
                    <el-descriptions-item v-if="documentInformation.pageCount" label="Page count:">{{documentInformation.pageCount}}</el-descriptions-item>
                    <el-descriptions-item v-if="documentInformation.wordCount" label="Word count:">{{documentInformation.wordCount}}</el-descriptions-item>
                </el-descriptions>

                <div v-if="limitReached">
                    <div class="title">
                        Segments that reached 500 characters limits.
                    </div>
                    <el-table :data="tableData" stripe style="width:100%">
                        <el-table-column prop="segmentNumber" label="Segment numbet" width="180" />
                        <el-table-column prop="startWith" label="Segment starts with" />
                    </el-table>
                </div>

            </el-collapse-item>
            </el-collapse>
</el-card>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'DocumentStatistics',
        props: {
            documentInformation: {
                type: Object,
                required: true,
            },
            documentName: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                collapseModel: [] as string[],
                collapsedItems: [] as string[],

                tooltipMessage: 'This file contains segments that reached 500 characters limit. See more inside.',
                tableData: [] as any,
                limitReached: false,
            };
        },
        mounted() {
            this.init();
        },
        computed: {
            tittle(): string {
                var tittle = 'Document statistics';

                if (this.collapseModel.includes('1')) {
                    return tittle;
                }

                return `${tittle} '${this.documentName}'`;
            }
        },
        watch: {
            tableData() {
                this.limitReached = this.tableData.length > 0;
            }
        },
        methods: {
            init() {
                this.tableData = this.documentInformation.invalidSegments
            },
            handleChange(val: string[]) {
                this.collapseModel = val;
            },
        },
    });

</script>

<style  lang="scss" scoped>
    .title-value {
        font-size: 19px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .limit-container {
        margin-left: 10px;
    }

    .limit-reached {
        background-image: url('@/images/alert.svg');
        width: 24px;
        height: 22px;
        background-repeat: no-repeat;
    }

    .title {
        display: flex;
        font-size: 16px;
        margin-top: 15px;
    }
</style>