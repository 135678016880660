<template>

    <el-button id="save-drafts"
               @click="saveDraftsDialogVisible = true"
               plain
               type="warning">
        Save all drafts to memory
        <div class="bttn-img">
            <span class="material-symbols-outlined">
                add_comment
            </span>
        </div>
    </el-button>

    <el-dialog v-model="saveDraftsDialogVisible"
               title="Save drafts"
               width="30%"
               align-center>
        <div class="message" v-html="saveMsgHtml"></div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="closeSaveDraftsDialog">Cancel</el-button>
                <el-button type="primary" @click="saveAllDrafts">
                    Confirm
                </el-button>
            </span>
        </template>
    </el-dialog>

</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        emits: ['saveConfirmed'],
        data() {
            return {
                saveDraftsDialogVisible: false,
                saveMsgHtml: `<p>${'This action will save all segments of this file with state'} <b>${'draft'}</b> ${'or'} <b>${'auto translated'}</b> ${'to the memory. Continue?'}</p>`,
            }
        },
        methods: {
            saveAllDrafts() {
                this.$emit('saveConfirmed');
                this.closeSaveDraftsDialog();
            },
            closeSaveDraftsDialog() {
                this.saveDraftsDialogVisible = false;
            },
        }
    })
</script>

<style lang="scss" scoped>
    .bttn-img {
        margin-left: 10px;
    }

    .message {
        font-size: 1.1em;
    }
</style>
