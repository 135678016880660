<template>

    <el-dropdown @click="pageTranslationDialogVisible = true"
                 size="default"
                 split-button
                 type="primary">
        Translate this page
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item @click="documentTranslationDialogVisible = true">
                    Translate all document
                </el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>

    <el-dialog v-model="pageTranslationDialogVisible"
               title="Translate this page"
               width="40%"
               :show-close="false"
               align-center>
        <div class="message">{{pageTranslationMsg}} </div>

        <el-collapse accordion>
            <el-collapse-item title="External translations" name="1">
                <div>
                    <el-checkbox v-model="useExternalTranslation"
                                 :disabled="!externalTranslationProviderAvailable"
                                 label="Use external translations"
                                 size="large" />

                    <el-alert type="warning"
                              :class="{ hidden: externalTranslationProviderAvailable }"
                              :title="translationProviderNotConfigured"
                              :closable="false" />
                </div>

            </el-collapse-item>
        </el-collapse>



        <template #footer>
            <span class="dialog-footer">
                <el-button @click="closePageTranslateDialog">Cancel</el-button>
                <el-button type="primary" @click="translatePage">
                    Confirm
                </el-button>
            </span>
        </template>
    </el-dialog>

    <el-dialog v-model="documentTranslationDialogVisible"
               title="Translate all document"
               width="40%"
               :show-close="false"
               align-center>
        <div class="message">{{documentTranslationMsg}} </div>

        <el-collapse accordion>
            <el-collapse-item title="External translations" name="1">
                <div>
                    <el-checkbox v-model="useExternalTranslation"
                                 :disabled="!externalTranslationProviderAvailable"
                                 label="Use external translations"
                                 size="large" />

                    <el-alert type="warning"
                              :class="{ hidden: externalTranslationProviderAvailable }"
                              :title="translationProviderNotConfigured"
                              :closable="false" />
                </div>

            </el-collapse-item>
        </el-collapse>



        <template #footer>
            <span class="dialog-footer">
                <el-button @click="closeDocumentTranslateDialog">Cancel</el-button>
                <el-button type="primary" @click="translateAll">
                    Confirm
                </el-button>
            </span>
        </template>
    </el-dialog>


</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { TranslationConfiguration } from '@/models/TranslationConfiguration';

    export default defineComponent({
        props: {
            configuration: Object as PropType<TranslationConfiguration>,
        },
        emits: ['translatePageConfirmed', 'translateAllConfirmed'],
        data() {
            return {
                pageTranslationDialogVisible: false,
                pageTranslationTittle: 'Translate this page',
                pageTranslationMsg: 'This action will translate this page. All segments with no matches in the memory will remain closed. Contitue?',

                documentTranslationDialogVisible: false,
                documentTranslationTittle: 'Translate all document',
                documentTranslationMsg: 'This action will translate this document. All segments with no matches in the memory will remain closed. Contitue?',

                useExternalTranslation: false,
                externalTranslationsMessage: 'Use external translations if no matches found. This action will use characters for external translations from your group.',
                translationProviderNotConfigured: 'Please, setup external translations provider first in configuration panel for this document.',
            }
        },
        computed: {
            externalTranslationProviderAvailable(): boolean {
                return this.configuration != null && 
                    this.configuration.useExternalTranslation &&
                    this.configuration.externalTranslationProviders != null &&
                    this.configuration.externalTranslationProviders.length > 0;
            }
        },
        methods: {
            closePageTranslateDialog() {
                this.pageTranslationDialogVisible = false;
                this.useExternalTranslation = false;
            },
            closeDocumentTranslateDialog() {
                this.documentTranslationDialogVisible = false;
                this.useExternalTranslation = false;
            },
            translatePage() {
                this.$emit('translatePageConfirmed', this.useExternalTranslation);
                this.closePageTranslateDialog();
            },
            translateAll() {
                this.$emit('translateAllConfirmed', this.useExternalTranslation);
                this.closeDocumentTranslateDialog();
            },
        }
    })

</script>

<style lang="scss" scoped>

    .message {
        margin-bottom: 20px;
        font-size: 1.1em;
    }

    .hidden {
        display: none;
    }
</style>