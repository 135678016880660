import { defineStore } from 'pinia'

import { pagingStore } from './pagingStore';

import { SegmentEditorState } from '@/models/SegmentEditorState' 
import { SegmentEditorItem } from '@/models/SegmentEditorItem';
import { SegmentEditorItemType } from '@/pages/Document/SegmentEditorItemType';
import { TranslationConfiguration } from '@/models/TranslationConfiguration';
import { TranslationState } from '@/models/TranslationSegment/TranslationState';


export const segmentsStore = defineStore('segmentsStore', {

    state: () => ({
        segmentEditorState: SegmentEditorState.Translate,

        currentSegmentIndex: 0,
        currentSegmentId: 0,

        segmentsArray: [] as SegmentEditorItem[],

        translationConfiguration: null as TranslationConfiguration | null,

        pagingStore: pagingStore(),
    }),

    getters: {
        renderSegments: (state) => state.segmentsArray.filter((s: SegmentEditorItem) => s.segmentIsVisibleOnList == true),
        isMergeActive: (state) => state.segmentEditorState == SegmentEditorState.Merge,
        isSplitActive: (state) => state.segmentEditorState == SegmentEditorState.Split,
    },

    actions: {  
        initStore() {
            this.segmentEditorState = SegmentEditorState.Translate;
            this.currentSegmentIndex = 0;
            this.currentSegmentId = 0;
            this.segmentsArray = [];
            this.translationConfiguration = null;
        },

        buildSegmentProperties() {
            this.setDefaultSgmentIndex();
            const baseNumber = this.pagingStore.currentPageBaseIndex;
            let numberIterator = 1;

            for (let i = 0; i < this.segmentsArray.length; i++) {

                if (this.segmentsArray[i].segmentType == SegmentEditorItemType.TranslationSegment) {
                    this.segmentsArray[i].rowNumber = baseNumber + numberIterator;
                    numberIterator++;
                }
            }

            this.setMergePossible();
            this.setFocusToSegment();
        },

        setDefaultSgmentIndex() {
            if (this.segmentsArray.length <= this.currentSegmentIndex)
            {
                this.currentSegmentIndex = this.segmentsArray.length - 1;
            }
        },

        setMergePossible() {
            const index = this.segmentsArray.slice().reverse()
                .findIndex((s: SegmentEditorItem) => s.segmentType == SegmentEditorItemType.TranslationSegment);
            const count = this.segmentsArray.length - 1
            const finalIndex = index >= 0 ? count - index : index;

            if (this.segmentsArray[finalIndex]) {
                this.segmentsArray[finalIndex].mergePossible = false;
            }
        },

        getConfig() {
            return this.translationConfiguration;
        },

        setTranslationConfiguration(configurations: TranslationConfiguration) {
            this.translationConfiguration = configurations;
        },

        clearFocusFromSegment() {
            if (this.segmentsArray[this.currentSegmentIndex]) {
                this.segmentsArray[this.currentSegmentIndex].focused = false;
            }
        },

        setFocusToSegment() {
            if (this.segmentsArray[this.currentSegmentIndex]) {
                this.segmentsArray[this.currentSegmentIndex].focused = true;
            }
        },

        setTranslateState() {
            this.segmentEditorState = SegmentEditorState.Translate;
        },

        setMergeState() {
            this.segmentEditorState = SegmentEditorState.Merge;
        },

        setSplitState() {
            this.segmentEditorState = SegmentEditorState.Split;
        },

        setCurrentSegmentIndex(index: number) {
            if (this.segmentsArray[index]) {
                this.setCurrentSegmentId(this.segmentsArray[index].itemId);
                return true;
            }
            return false;
        },

        setCurrentSegmentId(id: number) {
            if (this.currentSegmentId == id) {
                return;
            }

            this.clearFocusFromSegment();

            this.currentSegmentId = id;
            const index = this.getSegmentPosition(id);
            this.currentSegmentIndex = index

            this.setFocusToSegment();
        },

        increaseCurrentSegmentIndex() {
            const newIndex = this.currentSegmentIndex + 1;
            return this.setCurrentSegmentIndex(newIndex);
        },

        decreaseCurrentSegmentIndex() {
            const newIndex = this.currentSegmentIndex - 1;
            return this.setCurrentSegmentIndex(newIndex);
        },

        setSegmentsArray(segments: SegmentEditorItem[]) {
            this.segmentsArray = segments;
        },

        removeSegmentFromSegmentsArray(index: number, count: number = 1) {
            this.segmentsArray.splice(index, count);
        },

        addSegmentToArra(startPosition: number, segment: SegmentEditorItem) {
            this.segmentsArray.splice(startPosition, 0, segment);
        },

        addMultipleSegmentToArray(startPosition: number, segments: SegmentEditorItem[]) {
            this.segmentsArray.splice(startPosition, 0, ...segments);
        },

        getSegmentEditorItem(itemId: number)  {
            const segmentEditorItem = this.segmentsArray.find((s: SegmentEditorItem) => s.itemId == itemId);
            if (!segmentEditorItem) {
                throw new Error(`SegmentEditorItem not found for id: ${itemId}`);
            }

            return segmentEditorItem;
        },

        getCurrentSegmentEditorItem(): SegmentEditorItem {
            return this.segmentsArray[this.currentSegmentIndex];
        },

        getSegmentPosition(segmentId: number) {
            return this.segmentsArray.findIndex((s: SegmentEditorItem) => s.itemId == segmentId && s.segmentType == SegmentEditorItemType.TranslationSegment);
        },

        getNextWorkSegmentIndex() {
            let newIndex = 0;
            for (let i = this.currentSegmentIndex + 1; i < this.segmentsArray.length; i++) {
                const segment = this.segmentsArray[i].data ;
                if (segment.translationState == TranslationState.Draft ||
                    segment.translationState == TranslationState.NotTranslated ||
                    segment.translationState == TranslationState.PreTranslated ||
                    segment.translationState == TranslationState.AutoTranslated) {
                    newIndex = i;
                    break;
                }
            }

            if (newIndex == 0) {
                return -1;
            }

            return newIndex;
        },

    }
})
