<template>
    <div v-if="multiLingialAvailable"
         class="locale-changer">

        <el-select v-model="$i18n.locale"
                   @change="setLanguage">
            <el-option v-for="(lang, i) in langs"
                    :key="`Lang${i}`"
                    :value="lang"
                    :label="lang">
            </el-option>
        </el-select>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { languageService } from '@/services/languageService'
    import { userService } from '@/services/userService'

    export default defineComponent({
        data() {
            return {
                multiLingialAvailable: false,
                langs: languageService.getLanguages(),
                userService: new userService(),
            }
        },
        mounted() {
            this.multiLingialAvailable = languageService.multiLingualAvailable();
        },
        methods: {
            async setLanguage(newVal: any) {
                console.log(newVal);
                //await this.userService.setUserLanguage(newVal);
            }
        }
    })

</script>

<style scoped>

    .locale-changer{
        width: 60px;
    }

</style>