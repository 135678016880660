<template>
    <el-col :span="6"
            id="source-lagnuage">

        <p>Source Language</p>
        <el-select v-model="configuration.sourceLanguageId"
                   @change="valueChanged"
                   placeholder="Source Language"
                   default-first-option
                   filterable
                   :disabled="disabled">

            <el-option v-for="item in sourceLanguages"
                       :key="item.id"
                       :label="`${item.code} (${item.name})`"
                       :value="item.id" />


        </el-select>

    </el-col>

    <el-col class="centred-container"
            id="swap-languages"
            :span="1">


        <div v-if="!disabled"
             class="swap-img-container"
             @click="swapLanguages">
            <span class="material-symbols-outlined">
                sync_alt
            </span>
        </div>

    </el-col>

    <el-col :span="5"
            id="target-laguage">

        <p>Target Language</p>
        <el-select v-model="configuration.targetLanguageId"
                   @change="valueChanged"
                   placeholder="Target Languages"
                   default-first-option
                   filterable
                   :disabled="disabled">

            <el-option v-for="(item) in targetLanguages"
                       :key="item.id"
                       :label="`${item.code} (${item.name})`"
                       :value="item.id" />

        </el-select>
    </el-col>

</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { TranslationConfiguration } from '@/models/TranslationConfiguration'
    import { ConfigurationOptions } from '@/models/ConfigurationOptions'

    import { translationConfigService } from '../translationConfigService'
    import { notificationService } from '@/services/notificationService';

    export default defineComponent({
        props: {
            modelValue: Object,
            disabled: {
                type: Boolean,
                defaultValue: false
            },
        },
        data() {
            return {
                translationConfigService: new translationConfigService(),
                notificationService: new notificationService(),

                configurationOptions: null as ConfigurationOptions | null,
            }
        },
        emits: ['input', 'valueChanged'],
        mounted() {
            this.init();
        },
        computed: {
            configuration: {
                get(): TranslationConfiguration { return this.modelValue as TranslationConfiguration; },
                set(value: TranslationConfiguration) { this.$emit('input', value); }
            },
            sourceLanguages(): any {
                return this.configurationOptions?.languages;
            },
            targetLanguages(): any {
                return this.configurationOptions?.languages;
            },
        },
        methods: {
            async init() {
                this.configurationOptions = await this.translationConfigService.getConfigOptions()

                if (this.configurationOptions.languages.length < 2) {
                    this.notificationService.errorPermanent("There are no languages available for translation", "Please contact administrator or try to click log out and log in again");
                    return;
                }

                this.initlanguageValues();
            },
            initlanguageValues() {
                if (this.configuration.sourceLanguageId == -1) {
                    let sourceLanguage = this.getLanguageByCode('DA')

                    if (sourceLanguage == null || sourceLanguage == undefined) {
                        sourceLanguage = this.getLanguageByIndex(0)
                    }

                    this.configuration.sourceLanguageId = sourceLanguage.id
                }

                if (this.configuration.targetLanguageId == -1) {
                    let targetLanguage = this.getLanguageByCode('EN')

                    if (targetLanguage == null || targetLanguage == undefined) {
                        targetLanguage = this.getLanguageByIndex(1)
                    }

                    this.configuration.targetLanguageId = targetLanguage.id
                }
            },
            getLanguageByCode(code: string): any {
                return this.configurationOptions?.languages
                    .find((language: any) => language.code === code);
            },
            getLanguageByIndex(index: number): any {
                return this.configurationOptions?.languages[index];
            },
            swapLanguages() {
                let tmp = this.configuration.sourceLanguageId
                this.configuration.sourceLanguageId = this.configuration.targetLanguageId
                this.configuration.targetLanguageId = tmp

                this.valueChanged();
            },
            valueChanged() {
                this.$emit('valueChanged');
            },
        }
    })
</script>

<style lang="scss" scoped>

    .centred-container {
        display: flex;
    }

    .swap-img-container {
        cursor: pointer;
        opacity: 0.6;
        transition: 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #303133;
    }
</style>
