<template>
    <div>
        <TranslationConfig v-model="translationConfig"
                           :view-mode="true"
                           :documentType="documentType"
                           pageNamePrefix="Current document"
                           />
        
        <SegmentListPreview />
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { DocumentType } from '@/models/DocumentType'

    import TranslationConfig from '@/components/TranslationConfig/TranslationConfig.vue'
    import SegmentListPreview from './SegmentListPreview.vue'

    import { archivedDocumentService } from './archivedDocumentService'


    export default defineComponent({
        components: {
            TranslationConfig,
            SegmentListPreview,
        },
        data() {
            return {
                loading: false,
                translationConfig: null,
                documentConfiguration: null as any,
                archivedDocumentService: new archivedDocumentService(),
            };
        },
        mounted() {
            this.loading = true;
            this.loadDocConfigurations();
            this.loading = false;
        },
        computed: {
            documentId(): number {
                return Number(this.$route.params.id);
            },
            documentType(): DocumentType {
                return this.documentConfiguration != null
                    ? this.documentConfiguration.documentType
                    : DocumentType.Docx;
            },
        },
        methods: {
            async loadDocConfigurations() {
                this.documentConfiguration = await this.archivedDocumentService.loadConfigurations(this.documentId)
                this.translationConfig = this.documentConfiguration?.translationConfig;
            },
        },

    })
</script>
