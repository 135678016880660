import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5c2b3af3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_button, {
      id: "submitt-file",
      class: "upload-button",
      disabled: _ctx.submitDisabled,
      onClick: _ctx.sublitButtonHandler,
      type: "primary"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('fileUploadPrompt.startTranslation')), 1)
      ]),
      _: 1
    }, 8, ["disabled", "onClick"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.uploadFileDialogVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.uploadFileDialogVisible) = $event)),
      title: _ctx.$t('fileUploadPrompt.uploadFileTittle'),
      width: "30%",
      "align-center": ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_button, {
            onClick: _ctx.neverShowDialog,
            type: "info"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('fileUploadPrompt.neverShowDialog')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createElementVNode("div", null, [
            _createVNode(_component_el_button, {
              onClick: _ctx.uploadFile,
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('fileUploadPrompt.continue')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_el_button, { onClick: _ctx.closePopup }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('fileUploadPrompt.backToConfigurations')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('fileUploadPrompt.uploadFileText')), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ]))
}