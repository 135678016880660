import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslatePageOrDocumentButton = _resolveComponent("TranslatePageOrDocumentButton")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_SaveAllDraftsButton = _resolveComponent("SaveAllDraftsButton")!
  const _component_ClearDocumentButton = _resolveComponent("ClearDocumentButton")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, { gutter: 24 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 6,
          id: "translate-all"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TranslatePageOrDocumentButton, {
              configuration: _ctx.translationConfig,
              onTranslatePageConfirmed: _ctx.translatePage,
              onTranslateAllConfirmed: _ctx.translateAll
            }, null, 8, ["configuration", "onTranslatePageConfirmed", "onTranslateAllConfirmed"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode(_component_SaveAllDraftsButton, { onSaveConfirmed: _ctx.saveAllDrafts }, null, 8, ["onSaveConfirmed"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode(_component_ClearDocumentButton, { onClearConfirmed: _ctx.clearAllSegments }, null, 8, ["onClearConfirmed"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              id: "download-document",
              onClick: _ctx.downloadDoc
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Download document ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}