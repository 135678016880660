<template>
    <div class="go-back-container">

        <el-tooltip placement="top-start"
                    :content="nextTranslateSegmentTooltip"
                    :show-after="5">

            <div class="img-circle">
                <div @click="nextTranslateSegment" class="img-button-container">
                    <span class="material-symbols-outlined">
                        arrow_back
                    </span>
                </div>
            </div>


        </el-tooltip>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        computed: {
            nextTranslateSegmentTooltip(): string {
                return "Go back"
            },
        },
        methods: {
            nextTranslateSegment() {
                history.back()
            }
        }

    });
</script>

<style lang="scss" scoped>

    .go-back-container {
        left: -80px;
        position: absolute;
    }

    .img-circle {
        background-color: white;
        border-radius: 24px;
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
    }

</style>
