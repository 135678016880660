<template>
    <div class="img-wrapper">
        <div :class="['file', fileTypeClass ]">
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    import { DocumentRow } from '@/models/DocumentRow'
    import { DocumentType } from '@/models/DocumentType'

    export default defineComponent({
        props: {
            document: Object as PropType<DocumentRow>
        },
        data() {
            return {
            }
        },
        computed: {
            fileTypeClass() {
                if(this.document == undefined)
                    return ''

                switch (this.document.documentType) {
                    case DocumentType.Docx:
                        return 'word';
                    case DocumentType.Xlsx:
                        return 'excel';
                    case DocumentType.Pptx:
                        return 'powerpoint';
                }

                return ''
            },
        },

    })

</script>

<style lang="scss" scoped>

    @mixin background-image-color($url, $color, $size: contain, $position: center) {
        mask-image: $url;
        mask-size: $size;
        mask-position: $position;
        mask-repeat: no-repeat;
        -webkit-mask-image: $url;
        -webkit-mask-size: $size;
        -webkit-mask-position: $position;
        -webkit-mask-repeat: no-repeat;
        background-color: $color;
    }

    .img-wrapper {
        display: flex;
        justify-content: space-around;

        .file {
            width: 36px;
            height: 36px;
        }

        .word {
            @include background-image-color(url('@/images/FileTypes/word-logo.svg'), var(--word-file));
        }

        .excel {
            @include background-image-color(url('@/images/FileTypes/excel-logo.svg'), var(--excel-file));
        }

        .powerpoint {
            @include background-image-color(url('@/images/FileTypes/powerpoint-logo.svg'), var(--powerpoint-file));
        }
    }

</style>