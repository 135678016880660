import { createI18n } from 'vue-i18n'
import { userService } from '@/services/userService'
import en from '../languages/en.json'
import da from '../languages/da.json'

export class languageService {

    public static getLanguages() {
        return ['da', 'en']
    }

    public static getLocale() {
        return 'en'
        //this is default and temporary befole all languages available

        let userLocale = navigator.language;
        const userServiceInstance = new userService();
        const user = userServiceInstance.getUserFromCache();

        if (user != null) {
            if (user.interfaceLanguage != '') {
                userLocale = user.interfaceLanguage
            }
        }

        return userLocale;
    }

    public static getMessages() {
        const messages = {
            en: en,
            da: da,
        }

        return messages;
    }

    public static getI18n() {
        const i18n = createI18n({
            locale: this.getLocale(),
            fallbackLocale: 'en',
            messages: this.getMessages()
        })
        return i18n;
    }

    public static multiLingualAvailable() {
        const systemsString = process.env.VUE_APP_MULTI_LINGUAL;
        const systemsArray = JSON.parse(systemsString)

        const userServiceInstance = new userService();
        const user = userServiceInstance.getUserFromCache();

        if (user != null) {
            const exist = systemsArray.includes(user.groupId);
            return exist;            
        }

        return false;
    }

    public static setLocale(i18n: any ,newLocale: string) {
        i18n.locale = newLocale;
    }

}