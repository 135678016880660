import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
import { TranslationConfiguration } from '@/models/TranslationConfiguration';
import { SegmentStatus } from '@/models/TranslationSegment/SegmentStatus';
import { ExternalTranslationResult } from '@/models/TranslationSegment/TranslationVariants';

import { segmentApiService } from './segmentApiService';
import { deltaHelperService } from './deltaHelperService';
import { segmentStateService } from './segmentStateService';
import { translationVariantsService } from './translationVariantsService';
import { segmentTextLogicService } from './segmentTextLogicService';

export class segmentModificationService {
    private static instance: segmentModificationService;

    private segmentApiService: segmentApiService
    private deltaHelperService: deltaHelperService
    private segmentStateService: segmentStateService
    private translationVariantsService: translationVariantsService
    private segmentTextLogicService: segmentTextLogicService

    private constructor() {
        this.segmentApiService = segmentApiService.getInstance();
        this.deltaHelperService = new deltaHelperService();
        this.segmentStateService = segmentStateService.getInstance();
        this.translationVariantsService = translationVariantsService.getInstance();
        this.segmentTextLogicService = segmentTextLogicService.getInstance();
    }

    public static getInstance(): segmentModificationService {
        if (!segmentModificationService.instance) {
            segmentModificationService.instance = new segmentModificationService();
        }
        return segmentModificationService.instance;
    }

    private copySoureceToTargetDraft(selectedSegment: TranslationSegment): TranslationSegment {
        selectedSegment.draftData = { ...selectedSegment.sourceData };
        this.segmentStateService.setStateDraft(selectedSegment);

        return selectedSegment;
    }

    async copyAction(segment: TranslationSegment): Promise<void>{
        this.translationVariantsService.removeSelectedTranslationVariant(segment);
        this.copySoureceToTargetDraft(segment);

        await this.segmentApiService.saveSegmentChnages(segment);
    }

    async clearTranslationAction(segment: TranslationSegment): Promise<void> {

        this.deltaHelperService.clearDraft(segment);
        this.segmentStateService.clearSelectedTranslation(segment);
        this.segmentStateService.setStateNotTranslated(segment);

        await this.segmentApiService.saveSegmentChnages(segment);
    }

    async translateAction(segment: TranslationSegment, configuration: TranslationConfiguration): Promise<boolean> {
        const newSegment = await this.segmentApiService.translateSegment(segment, configuration);

        if (newSegment === null) {
            this.translationVariantsService.markSegmentsAsLoadded(segment);
            return false;
        }

        this.mapTranslationSegment(segment, newSegment);
        return true;
    }

    async reloadSegmentTranslationsAction(segment: TranslationSegment, configuration: TranslationConfiguration): Promise<boolean> {
        const newSegment = await this.segmentApiService.reloadSegmentTranslations(segment, configuration);

        this.mapTranslationSegment(segment, newSegment);
        return true;
    }

    async addTranslationAction(segment: TranslationSegment, configuration: TranslationConfiguration): Promise<void> {
        await this.handleSegmentStatus(segment);

        const newSegment = await this.segmentApiService.addNewTranslationStyled(segment, configuration);
        if (newSegment === null) return;

        this.mapTranslationSegment(segment, newSegment);
    }

    async updateTranslationAction(segment: TranslationSegment, configuration: TranslationConfiguration): Promise<void> {

        await this.handleSegmentStatus(segment);

        let newSegment;

        if (this.segmentTextLogicService.drftTextEqalTranslationText(segment) &&
            !this.segmentTextLogicService.drftStyleEqalTranslationStyle(segment)) {
            newSegment = await this.segmentApiService.updateSegmentStyles(segment, configuration);
        }
        else {
            newSegment = await this.segmentApiService.updateTranslationStyled(segment, configuration);
        }

        if (newSegment === null) return;

        this.mapTranslationSegment(segment, newSegment);
    }

    async clearSegmnetStylesAction(segment: TranslationSegment): Promise<void>{

        const segmentText = segment.draftData.text.trim();

        if (segmentText == '') {
            return;
        }

        segment.draftData = this.deltaHelperService.mapToTextData(segmentText);
        await this.segmentApiService.saveSegmentChnages(segment);
    }

    mapTranslationSegment(segment: TranslationSegment, newSegment: TranslationSegment): void{
        segment.segmentTranslation = newSegment.segmentTranslation;
        segment.draftData = newSegment.draftData;
        segment.translationVariants = newSegment.translationVariants;
        segment.termPositions = newSegment.termPositions;
        segment.terminologyVariants = newSegment.terminologyVariants;
        segment.segmentStatus = newSegment.segmentStatus;
        segment.translationState = newSegment.translationState;
        segment.externalTranslationVariants = newSegment.externalTranslationVariants;
    }

    async handleSegmentStatus(segment: TranslationSegment): Promise<void> {
        if (segment.segmentStatus == SegmentStatus.Undefined) {
            this.segmentStateService.setSegmentStatus(segment);
        }

        if (segment.segmentStatus != SegmentStatus.Ok) {
            await this.segmentApiService.saveSegmentChnages(segment);
        }
    }

    async setExternalTranslationAsDraft(translationSegment: TranslationSegment, variant: ExternalTranslationResult) {
        translationSegment.draftData = this.deltaHelperService.mapToTextData(variant.translation);
        translationSegment.segmentTranslation = null;

        this.segmentStateService.setStateAutoTranslated(translationSegment);
        await this.segmentApiService.saveSegmentState(translationSegment);
    }
}